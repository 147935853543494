import api from "@app/services/api";
import { generateFormdata } from "@app/helpers";

export const SET_CANDIDATE = "SET_CANDIDATE";
export const setCandidate = (data) => ({
    payload: data,
    type: SET_CANDIDATE,
});

export const DECLINE_MODAL = "DECLINE_MODAL";
export const handleDeclineModal = () => ({
    type: DECLINE_MODAL,
});

export const ADVANCE_MODAL = "ADVANCE_MODAL";
export const handleAdvanceModal = () => ({
    type: ADVANCE_MODAL,
});

export const POSTIONS_CLEAR_ACTION = "POSTIONS_CLEAR_ACTION";
export const clearAction = () => ({
    type: POSTIONS_CLEAR_ACTION,
});

export const FILTER_DATA_POSITIONS = "FILTER_DATA_POSITIONS";
export const filterDataPositions = (data) => {
    return {
        payload: data,
        type: FILTER_DATA_POSITIONS,
    };
};

export const POSITIONS_SEARCH_DATA = "POSITIONS_SEARCH_DATA";
export const positionsSearchData = (keyword) => {
    return {
        keyword,
        type: POSITIONS_SEARCH_DATA,
    };
};

export const FILTER_DATA_CANDIDATES = "FILTER_DATA_CANDIDATES";
export const filterDataCandidates = (data) => {
    return {
        payload: data,
        type: FILTER_DATA_CANDIDATES,
    };
};

export const CANDIDATES_SEARCH_DATA = "CANDIDATES_SEARCH_DATA";
export const candidatesSearchData = (keyword) => {
    return {
        keyword,
        type: CANDIDATES_SEARCH_DATA,
    };
};

export const SET_POSITION_ATTRIBUTE = "SET_POSITION_ATTRIBUTE";
export const setPositionAtribute = (key, value) => {
    return {
        key,
        value,
        type: SET_POSITION_ATTRIBUTE,
    };
};

export const SET_SEARCH_KEYS = "SET_SEARCH_KEYS";
export const setSearchKeys = (keys) => ({
    payload: keys,
    type: SET_SEARCH_KEYS,
});

export const CLEAN_UP_POSITION_DETAILS = "CLEAN_UP_POSITION_DETAILS";
export const cleanUpPositionDetails = () => ({
    type: CLEAN_UP_POSITION_DETAILS,
});

export const SORT_DATA_POSITIONS = "SORT_DATA_POSITIONS";
export const sortDataByDate = () => ({
    type: SORT_DATA_POSITIONS,
});

// =====>>> Api endpoints  <<<======

export const GET_POSTIONS_DETAILS_BEGIN = "GET_POSTIONS_DETAILS_BEGIN";
export const getPostionsDetailsBegin = () => ({
    type: GET_POSTIONS_DETAILS_BEGIN,
});

export const GET_POSTIONS_DETAILS_SUCCESS = "GET_POSTIONS_DETAILS_SUCCESS";
export const getPostionsDetailsSuccess = (data) => ({
    payload: data,
    type: GET_POSTIONS_DETAILS_SUCCESS,
});

export const GET_POSTIONS_DETAILS_FAILED = "GET_POSTIONS_DETAILS_FAILED";
export const getPostionsDetailsFailed = (error) => ({
    payload: error,
    type: GET_POSTIONS_DETAILS_FAILED,
});

export const GET_POSTIONS_DETAILS = "GET_POSTIONS_DETAILS";
export const getPostionsDetails = (clientId, positionId) => {
    return async (dispatch) => {
        try {
            dispatch(getPostionsDetailsBegin());
            const response = await api.get(`clients/${clientId}/positions/${positionId}`);
            dispatch(getPostionsDetailsSuccess(response.data));
        } catch (error) {
            dispatch(getPostionsDetailsFailed(error));
        }
    };
};

export const GET_CANDIDATES_BEGIN = "GET_CANDIDATES_BEGIN";
export const getCandidatesBegin = () => ({
    type: GET_CANDIDATES_BEGIN,
});

export const GET_CANDIDATES_SUCCESS = "GET_CANDIDATES_SUCCESS";
export const getCandidatesSuccess = (data) => ({
    payload: data,
    type: GET_CANDIDATES_SUCCESS,
});

export const GET_CANDIDATES_FAILED = "GET_CANDIDATES_FAILED";
export const getCandidatesFailed = (error) => ({
    payload: error,
    type: GET_CANDIDATES_FAILED,
});

export const GET_CANDIDATES = "GET_CANDIDATES";
export const getCandidates = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getCandidatesBegin());
            const response = await api.get(`clients/${clientId}/candidates`);
            dispatch(getCandidatesSuccess(response.data));
        } catch (error) {
            dispatch(getCandidatesFailed(error));
        }
    };
};

export const ACCEPT_CANDIDATE_BEGIN = "ACCEPT_CANDIDATE_BEGIN";
export const acceptCandidateBegin = () => ({
    type: ACCEPT_CANDIDATE_BEGIN,
});

export const ACCEPT_CANDIDATE_SUCCESS = "ACCEPT_CANDIDATE_SUCCESS";
export const acceptCandidateSuccess = (data) => ({
    payload: data,
    type: ACCEPT_CANDIDATE_SUCCESS,
});

export const ACCEPT_CANDIDATE_FAILED = "ACCEPT_CANDIDATE_FAILED";
export const acceptCandidateFailed = (error) => ({
    payload: error,
    type: ACCEPT_CANDIDATE_FAILED,
});

export const ACCEPT_CANDIDATE = "ACCEPT_CANDIDATE";
export const acceptCandidate = (clientId, candidateId, positionId) => {
    return async (dispatch) => {
        try {
            dispatch(acceptCandidateBegin());
            const response = await api.post(`clients/${clientId}/candidates/${candidateId}/accept`);
            dispatch(acceptCandidateSuccess(response.data));
            positionId ? dispatch(getPostionsDetails(clientId, positionId)) : dispatch(getCandidates(clientId));
        } catch (error) {
            dispatch(acceptCandidateFailed(error));
        }
    };
};

export const DECLINE_CANDIDATE_BEGIN = "DECLINE_CANDIDATE_BEGIN";
export const declineCandidateBegin = () => ({
    type: DECLINE_CANDIDATE_BEGIN,
});

export const DECLINE_CANDIDATE_SUCCESS = "DECLINE_CANDIDATE_SUCCESS";
export const declineCandidateSuccess = (data) => ({
    payload: data,
    type: DECLINE_CANDIDATE_SUCCESS,
});

export const DECLINE_CANDIDATE_FAILED = "DECLINE_CANDIDATE_FAILED";
export const declineCandidateFailed = (error) => ({
    payload: error,
    type: DECLINE_CANDIDATE_FAILED,
});

export const DECLINE_CANDIDATE = "DECLINE_CANDIDATE";
export const declineCandidate = (clientId, candidateId, positionId, data = null) => {
    return async (dispatch) => {
        try {
            dispatch(declineCandidateBegin());
            const response = await api.post(`clients/${clientId}/candidates/${candidateId}/reject`, data);
            dispatch(declineCandidateSuccess(response.data));
            positionId ? dispatch(getPostionsDetails(clientId, positionId)) : dispatch(getCandidates(clientId));
        } catch (error) {
            dispatch(declineCandidateFailed(error));
        }
    };
};

export const SCHEDULE_CALL_BEGIN = "SCHEDULE_CALL_BEGIN";
export const scheduleCallBegin = () => ({
    type: SCHEDULE_CALL_BEGIN,
});

export const SCHEDULE_CALL_SUCCESS = "SCHEDULE_CALL_SUCCESS";
export const scheduleCallSuccess = (data) => ({
    payload: data,
    type: SCHEDULE_CALL_SUCCESS,
});

export const SCHEDULE_CALL_FAILED = "SCHEDULE_CALL_FAILED";
export const scheduleCallFailed = (error) => ({
    payload: error,
    type: SCHEDULE_CALL_FAILED,
});

export const SCHEDULE_CALL = "SCHEDULE_CALL";
export const scheduleCall = (clientId, candidateId, positionId) => {
    return async (dispatch) => {
        try {
            dispatch(scheduleCallBegin());
            const response = await api.post(`clients/${clientId}/candidates/${candidateId}/schedule-call`);
            dispatch(scheduleCallSuccess(response.data));
            positionId ? dispatch(getPostionsDetails(clientId, positionId)) : dispatch(getCandidates(clientId));
        } catch (error) {
            dispatch(scheduleCallFailed(error));
        }
    };
};

export const CREATE_POSTION_BEGIN = "CREATE_POSTION_BEGIN";
export const createPositionBegin = () => ({
    type: CREATE_POSTION_BEGIN,
});

export const CREATE_POSTION_SUCCESS = "CREATE_POSTION_SUCCESS";
export const createPositionSuccess = (data) => ({
    payload: data,
    type: CREATE_POSTION_SUCCESS,
});

export const CREATE_POSTION_FAILED = "CREATE_POSTION_FAILED";
export const createPositionFailed = (error) => ({
    payload: error,
    type: CREATE_POSTION_FAILED,
});

export const CREATE_POSITION = "CREATE_POSITION";
export const createPosition = (clientId, body, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(createPositionBegin());
            const formData = generateFormdata(body);
            const response = await api.post(`clients/${clientId}/positions`, formData);
            dispatch(createPositionSuccess(response.data));
            navigate("/positions");
        } catch (error) {
            dispatch(createPositionFailed(error));
        }
    };
};

export const EDIT_POSTION_BEGIN = "EDIT_POSTION_BEGIN";
export const editPositionBegin = () => ({
    type: EDIT_POSTION_BEGIN,
});

export const EDIT_POSTION_SUCCESS = "EDIT_POSTION_SUCCESS";
export const editPositionSuccess = (data) => ({
    payload: data,
    type: EDIT_POSTION_SUCCESS,
});

export const EDIT_POSTION_FAILED = "EDIT_POSTION_FAILED";
export const editPositionFailed = (error) => ({
    payload: error,
    type: EDIT_POSTION_FAILED,
});

export const EDIT_POSITION = "EDIT_POSITION";
export const editPosition = (clientId, id, body, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(editPositionBegin());
            const formData = generateFormdata(body);
            const response = await api.post(`clients/${clientId}/positions/${id}`, formData);
            dispatch(editPositionSuccess(response.data));
            navigate("/positions");
        } catch (error) {
            dispatch(editPositionFailed(error));
        }
    };
};

export const DELETE_JOB_DETAILS_FILE_BEGIN = "DELETE_JOB_DETAILS_FILE_BEGIN";
export const deleteJobDetailsFileBegin = () => ({
    type: DELETE_JOB_DETAILS_FILE_BEGIN,
});

export const DELETE_JOB_DETAILS_FILE_SUCCESS = "DELETE_JOB_DETAILS_FILE_SUCCESS";
export const deleteJobDetailsFileSuccess = (data) => ({
    payload: data,
    type: DELETE_JOB_DETAILS_FILE_SUCCESS,
});

export const DELETE_JOB_DETAILS_FILE_FAILED = "DELETE_JOB_DETAILS_FILE_FAILED";
export const deleteJobDetailsFileFailed = (error) => ({
    payload: error,
    type: DELETE_JOB_DETAILS_FILE_FAILED,
});

export const DELETE_JOB_DETAILS_FILE = "DELETE_JOB_DETAILS_FILE";
export const deleteJobDetailsFile = (clientId, id) => {
    return async (dispatch) => {
        try {
            dispatch(deleteJobDetailsFileBegin());
            const response = await api.delete(`clients/${clientId}/positions/${id}/remove-job-details-file`);
            dispatch(deleteJobDetailsFileSuccess(response.data));
        } catch (error) {
            dispatch(deleteJobDetailsFileFailed(error));
        }
    };
};

export const GET_POSITIONS_BEGIN = "GET_POSITIONS_BEGIN";
export const getPositionsBegin = () => ({
    type: GET_POSITIONS_BEGIN,
});

export const GET_POSITIONS_SUCCESS = "GET_POSITIONS_SUCCESS";
export const getPositionsSuccess = (data) => ({
    payload: data,
    type: GET_POSITIONS_SUCCESS,
});

export const GET_POSITIONS_FAILED = "GET_POSITIONS_FAILED";
export const getPositionsFailed = (error) => ({
    payload: error,
    type: GET_POSITIONS_FAILED,
});

export const getPositions = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getPositionsBegin());
            const response = await api.get(`clients/${clientId}/positions`);
            dispatch(getPositionsSuccess(response.data));
        } catch (error) {
            dispatch(getPositionsFailed(error));
        }
    };
};

export const DELETE_POSITION_BEGIN = "DELETE_POSITION_BEGIN";
export const deletePositionBegin = () => ({
    type: DELETE_POSITION_BEGIN,
});

export const DELETE_POSITION_SUCCESS = "DELETE_POSITION_SUCCESS";
export const deletePositionSuccess = (data) => ({
    payload: data,
    type: DELETE_POSITION_SUCCESS,
});

export const DELETE_POSITION_FAILED = "DELETE_POSITION_FAILED";
export const deletePositionFailed = (error) => ({
    payload: error,
    type: DELETE_POSITION_FAILED,
});

export const deletePosition = (clientId, positionId) => {
    return async (dispatch) => {
        try {
            dispatch(deletePositionBegin());
            const response = await api.delete(`clients/${clientId}/positions/${positionId}`);
            dispatch(deletePositionSuccess(response.data));
            dispatch(getPositions(clientId));
        } catch (error) {
            dispatch(deletePositionFailed(error));
        }
    };
};

export const CLOSE_POSITION_BEGIN = "CLOSE_POSITION_BEGIN";
export const closePositionBegin = () => ({
    type: CLOSE_POSITION_BEGIN,
});

export const CLOSE_POSITION_SUCCESS = "CLOSE_POSITION_SUCCESS";
export const closePositionSuccess = (data) => ({
    payload: data,
    type: CLOSE_POSITION_SUCCESS,
});

export const CLOSE_POSITION_FAILED = "CLOSE_POSITION_FAILED";
export const closePositionFailed = (error) => ({
    payload: error,
    type: CLOSE_POSITION_FAILED,
});

export const CLOSE_POSITION = "CLOSE_POSITION";
export const closePosition = (positionId, data) => {
    return async (dispatch, getState) => {
        try {
            const clientId = getState()?.authentication?.company?.id;

            dispatch(closePositionBegin());

            const response = await api.patch(`clients/${clientId}/positions/${positionId}/close`, data);

            dispatch(closePositionSuccess(response.data));

            dispatch(getPositions(clientId));
        } catch (error) {
            dispatch(closePositionFailed(error));
        }
    };
};
