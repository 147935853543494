import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUTH } from "@app/enums";
import { NotFound } from "@app/components";
import { currentRoleSwitched } from "@app/actions/authentication";

const TalentMiddleware = () => {
    const dispatch = useDispatch();
    const { userProfile } = useSelector((state) => state.authentication);
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        setShouldRender(userProfile?.is_talent || userProfile.roles?.includes("Applicant"));
    }, [userProfile]);

    useEffect(() => {
        if (shouldRender) {
            dispatch(currentRoleSwitched(AUTH.ROLE.TALENT));
        }
    }, [dispatch, shouldRender]);

    return shouldRender ? <Outlet /> : <NotFound />;
};

export default TalentMiddleware;
