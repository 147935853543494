import Fuse from "fuse.js";

import { positions } from "@app/actions";
import { removeDuplicatesByKey } from "@app/helpers";

const initialState = {
    positions: [],
    searchKeyword: "",
    candidatesData: [],
    filteredPositions: [],
    interviewerData: null,
    DESCSortByDate: false,
    showAdvaceModal: false,
    selectedCandidate: null,
    showDeclineModal: false,
    filteredCandidatesData: [],
    fuseSearch: new Fuse([], { keys: [], threshold: 0 }),
    fuseOptions: {
        keys: [],
        threshold: 0,
    },
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
    data: {
        name: "",
        rate: "",
        notes: "",
        rate_type: "",
        main_skills: [],
        description: "",
        commitment_id: 2,
        engagement_type: "",
        start_date_type: "",
        more_details_link: "",
        additional_skills: [],
        job_details_file: null,
        number_openings_client: "",
        preferred_working_hours: "",
    },
};

const filterCandidates = (checkedData, data) => {
    let result = data;
    checkedData.forEach((el) => {
        const dataToFilter = el.children.filter((child) => child._selected).map((child) => child[el.key]);
        if (dataToFilter.length === 0) return;
        if (el.parent === "Country") {
            result = result.filter((item) => dataToFilter.includes(item.candidate_profile[el.key]));
        }
        if (el.parent === "Position") {
            result = result.filter((item) => dataToFilter.includes(item.position[el.key]));
        }
        if (el.parent === "Status") {
            result = result.filter((item) => dataToFilter.includes(item[el.key]));
        }
    });
    return result;
};

const filterPositions = (checkedData, data) => {
    let result = data;
    checkedData.forEach((el) => {
        const dataToFilter = el.children.filter((child) => child._selected).map((child) => child[el.key]);
        if (dataToFilter.length === 0) return;
        result = result.filter((item) => dataToFilter.includes(item[el.key]));
    });
    return result;
};

const setSelctedVal = (data, checked) => {
    return data.map((el) => ({
        ...el,
        _selected: checked,
        position: { ...el.position, _selected: checked },
        candidate_profile: { ...el.candidate_profile, _selected: checked },
    }));
};

const setSelctedValPositions = (data, checked) => {
    return data.map((el) => ({
        ...el,
        _selected: checked,
    }));
};

const _reMapSkills = (data) => {
    return {
        ...data,
        main_skills: data.main_skills.map((el) => ({
            ...el,
            value: el.id,
            label: el.name,
        })),
        additional_skills: data.additional_skills.map((el) => ({
            ...el,
            value: el.id,
            label: el.name,
        })),
    };
};

const defaultSelectedValCandidates = (data, defaultVal) => {
    const defaultSelected = data
        .filter((el) => defaultVal.includes(el.status))
        .map((el) => ({ ...el, _selected: true }));
    return defaultSelected.concat(data.filter((el) => !defaultVal.includes(el.status)));
};

const sortData = (filteredData, DESCSortByDate) => {
    if (DESCSortByDate) {
        return filteredData.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
    return filteredData.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
};

export default function positionsReducer(state = initialState, action) {
    switch (action.type) {
        case positions.SET_CANDIDATE: {
            return {
                ...state,
                selectedCandidate: action.payload,
            };
        }
        case positions.DECLINE_MODAL: {
            return {
                ...state,
                showDeclineModal: !state.showDeclineModal,
            };
        }
        case positions.ADVANCE_MODAL: {
            return {
                ...state,
                showAdvaceModal: !state.showAdvaceModal,
            };
        }
        case positions.SET_SEARCH_KEYS: {
            return {
                ...state,
                fuseOptions: {
                    ...state.fuseOptions,
                    keys: action.payload,
                },
            };
        }
        case positions.POSTIONS_CLEAR_ACTION: {
            const _checkedFalse = setSelctedVal(state.candidatesData, false);
            const _positionsCheckedFalse = setSelctedValPositions(state.positions, false)
                .slice()
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            return {
                ...state,
                candidatesData: [..._checkedFalse],
                positions: [..._positionsCheckedFalse],
                filteredCandidatesData: [..._checkedFalse],
                filteredPositions: [..._positionsCheckedFalse],
                action: {
                    ...initialState.action,
                },
            };
        }
        case positions.GET_POSTIONS_DETAILS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.GET_POSTIONS_DETAILS_SUCCESS: {
            const _reMap = setSelctedVal(action.payload.data.position.candidates, false);
            return {
                ...state,
                candidatesData: [..._reMap],
                filteredCandidatesData: [..._reMap],
                data: _reMapSkills(action.payload.data.position),
                fuseSearch: new Fuse(action.payload.data.position.candidates, state.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case positions.GET_POSTIONS_DETAILS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.GET_CANDIDATES_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.GET_CANDIDATES_SUCCESS: {
            const interviewerData = () => {
                const data = action.payload.data;
                delete data.candidates;
                return data;
            };
            // Default selected Hired and Interview candidates===>
            const _reMap = defaultSelectedValCandidates(setSelctedVal(action.payload.data.candidates, false), [
                "Interview",
                "Hired",
            ]);
            const filteredData = filterCandidates(
                [
                    {
                        key: "status",
                        selectAll: false,
                        parent: "Status",
                        children: [..._reMap],
                    },
                    {
                        key: "country",
                        selectAll: false,
                        parent: "Country",
                        children: removeDuplicatesByKey(
                            action.payload.data.candidates.map((el) => el.candidate_profile),
                            "country",
                        ),
                    },
                    {
                        key: "name",
                        selectAll: false,
                        parent: "Position",
                        children: removeDuplicatesByKey(
                            action.payload.data.candidates.map((el) => el.position),
                            "name",
                        ),
                    },
                ],
                _reMap,
            );
            // =======

            return {
                ...state,
                candidatesData: [..._reMap],
                interviewerData: interviewerData(),
                filteredCandidatesData: [...filteredData],
                fuseSearch: new Fuse(action.payload.data.candidates, state.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case positions.GET_CANDIDATES_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.FILTER_DATA_CANDIDATES: {
            return {
                ...state,
                filteredCandidatesData: filterCandidates(action.payload, state.candidatesData),
            };
        }
        case positions.CANDIDATES_SEARCH_DATA: {
            return {
                ...state,
                searchKeyword: action.keyword,
                filteredCandidatesData:
                    action.keyword !== ""
                        ? state.fuseSearch.search(action.keyword).map((el) => el.item)
                        : [...state.candidatesData],
            };
        }
        case positions.ACCEPT_CANDIDATE_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.ACCEPT_CANDIDATE_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case positions.ACCEPT_CANDIDATE_FAILED: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.DECLINE_CANDIDATE_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.DECLINE_CANDIDATE_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case positions.DECLINE_CANDIDATE_FAILED: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.SCHEDULE_CALL_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.SCHEDULE_CALL_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case positions.SCHEDULE_CALL_FAILED: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.SET_POSITION_ATTRIBUTE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.key]: action.value,
                },
            };
        }
        case positions.CREATE_POSTION_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.CREATE_POSTION_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case positions.CREATE_POSTION_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.EDIT_POSTION_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.EDIT_POSTION_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case positions.EDIT_POSTION_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }

        case positions.CLOSE_POSITION_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.CLOSE_POSITION_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case positions.CLOSE_POSITION_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.DELETE_JOB_DETAILS_FILE_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.DELETE_JOB_DETAILS_FILE_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case positions.DELETE_JOB_DETAILS_FILE_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.GET_POSITIONS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                },
            };
        }
        case positions.GET_POSITIONS_SUCCESS: {
            const _reMap = defaultSelectedValCandidates(setSelctedValPositions(action.payload.data.positions, false), [
                "Filled",
                "Cancelled",
                "In-progress",
            ]);
            const filteredData = filterCandidates(
                [
                    {
                        key: "status",
                        parent: "Status",
                        selectAll: false,
                        children: [..._reMap],
                    },
                ],
                _reMap,
            ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            return {
                ...state,
                positions: [..._reMap],
                filteredPositions: [...filteredData],
                fuseSearch: new Fuse(action.payload.data.positions, state.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case positions.GET_POSITIONS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.DELETE_POSITION_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case positions.DELETE_POSITION_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case positions.DELETE_POSITION_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case positions.FILTER_DATA_POSITIONS: {
            return {
                ...state,
                filteredPositions: filterPositions(action.payload, state.positions)
                    .slice()
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
            };
        }
        case positions.POSITIONS_SEARCH_DATA: {
            return {
                ...state,
                searchKeyword: action.keyword,
                filteredPositions:
                    action.keyword !== ""
                        ? state.fuseSearch.search(action.keyword).map((el) => el.item)
                        : [...state.positions],
            };
        }
        case positions.CLEAN_UP_POSITION_DETAILS: {
            return {
                ...state,
                data: initialState.data,
                action: initialState.action,
            };
        }
        case positions.SORT_DATA_POSITIONS:
            return {
                ...state,
                DESCSortByDate: !state.DESCSortByDate,
                filteredPositions: sortData(state.filteredPositions, state.DESCSortByDate),
            };
        default: {
            return state;
        }
    }
}
